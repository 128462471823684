import React from "react";
import theme from "theme";
import { Theme, Text, Box, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact-us"} />
		<Helmet>
			<title>
				GrandSlam Courts
			</title>
			<meta name={"description"} content={"Meistern Sie Ihren Aufschlag unter unseren Lichtern"} />
			<meta property={"og:title"} content={"GrandSlam Courts"} />
			<meta property={"og:description"} content={"Meistern Sie Ihren Aufschlag unter unseren Lichtern"} />
			<link rel={"shortcut icon"} href={"https://zenitorfax.com/img/3176367.png"} type={"image/x-icon"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="row"
				justify-content="space-between"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
				align-self="auto"
				align-content="center"
				flex="0 0 auto"
			>
				<Box min-height="100px" min-width="400px">
					<Text
						margin="0px 0px 30px 0px"
						font="--headline1"
						color="--darkL2"
						text-align="center"
						sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						border-color="#00875A"
					>
						Nehmen Sie Kontakt mit uns auf
					</Text>
					<Text margin="0px 0px 48px 0px" font="--lead" color="--darkL2" text-align="center">
					Tauchen Sie noch heute in das GrandSlam Courts-Erlebnis ein. Um zu üben, anzufragen oder einfach nur, um über Ihre Liebe zum Spiel zu plaudern, wenden Sie sich an uns:
					</Text>
				</Box>
				<Box min-width="350px" min-height="100px" margin="0px 0px 0px 50px">
					<Link
						href="tel:+49 2307 73691"
						text-align="center"
						color="--green"
						font="--headline3"
						text-decoration-line="initial"
						margin="0px 0px 16px 0px"
						display="block"
					>
						+49 2307 73691
					</Link>
					<Link
						href="mailto:info@zenitorfax.com"
						text-align="center"
						color="--green"
						font="--headline3"
						text-decoration-line="initial"
						margin="0px 0px 16px 0px"
						display="block"
					>
						info@zenitorfax.com
					</Link>
					<Text
						margin="0px 0px 48px 0px"
						font="--headline3"
						color="--green"
						text-align="center"
						display="block"
					>
						Am Schwimmbad 10, 59174 Kamen, Deutschland
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652fbf0fdbe26f0020fd0d1b"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});